import { ALARM_HISTORY } from "../../../configuration/paths"

export const TableData = ({ className, navigate, parameters, text }) => {
    const parseDate = (range) => {
        return range.replace(' - ', '-').replaceAll(' ', '_')

    }

    const dataClick = () => {
        if (parameters !== undefined) {
            navigate(`${ALARM_HISTORY}?filter=&unit=${parameters.unit}&range=${parseDate(parameters.date)}${parameters.type ? `&type=${parameters.type}` : ''}${parameters.level ? `&level=${parameters.level}` : ''}`)
        }
    }

    return (
        <td onClick={dataClick} className={className}>
            {text}
        </td>
    )
}
