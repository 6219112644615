import React, { useCallback, useEffect, useState } from "react";
import { updateVehicleName } from "../../store";
import { EyeOutlined, VideoCameraOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Collapse, Divider, Input, Modal, Typography, } from "antd";
import Camera from "./Camera";
import { LatestAlert } from "./components/LatestAlert";
import './styles/infoPanel.scss'
import { VideoModal } from "../utility/components/VideoModal";
import { Metrics } from "./components/Metrics";
import { useSelector } from "react-redux";
import { UPTIME_HISTORY } from "../../configuration/paths";

const { Title, Text } = Typography;

const InfoPanel = ({ dispatch, item }) => {
    const [edit, setEdit] = useState(false);
    const [newName, setNewName] = useState('');
    const [offline, setOffline] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [openMetrics, setOpenMetrics] = useState(false)
    const [cameraProps, setCameraProps] = useState({})
    const role = useSelector(state => state.app.profile.role)
    const [url, setUrl] = useState('');
    const [items, setItems] = useState([]);

    const getOfflineStatus = () => {
        setOffline(new Date() - new Date(item.status_datetime) >= 60000)
    }

    const saveName = () => {
        setEdit(false);
        dispatch(updateVehicleName({ serial_number: item.serial_number, name: newName, unit_id: item.unit_id }))
    }

    const cancelName = () => {
        setEdit(false);
    }

    const determineHealth = useCallback(() => {
        return item.radar_health && item.lanes_health && item.object_health
    }, [item.radar_health, item.lanes_health, item.object_health])

    const externalLinkIcon = () => {
        return <svg viewBox="0 0 5 5" width={11} height={11} style={{ marginLeft: '4px' }}>
            <g>
                <line x1={0} y1={5} x2={5} y2={5} stroke="black" />
                <line x1={0} y1={0} x2={0} y2={5} stroke="black" />
                <line x1={0} y1={0} x2={2} y2={0} stroke="black" />
                <line x1={5} y1={3} x2={5} y2={5} stroke="black" />

                <line x1={1.5} y1={3.5} x2={5} y2={0} strokeWidth={0.5} stroke="black" />
                <line x1={2.75} y1={0} x2={5} y2={0} stroke="black" />
                <line x1={5} y1={0} x2={5} y2={2.25} stroke="black" />
            </g>
        </svg>
    }

    const celsiusToFahrenheit = (temp) => {
        return temp * 9 / 5 + 32
    }

    useEffect(() => {
        if (edit) {
            setNewName(item.vehicle_name)
        }
    }, [edit])

    useEffect(() => {
        getOfflineStatus()
        if (item.cameradetails) {
            setCameraProps({
                robotId: item.cameradetails[0].robotId,
                appId: item.cameradetails[0].appId,
                cameraStream: item.cameradetails[0].cameraStream
            })
        }
    }, [item])

    useEffect(() => {
        if (item) {
            let tempItems = []
            if (role !== 'user') {
                tempItems = tempItems.concat({
                    key: 0,
                    label: 'Metrics',
                    children: <div>
                        <a className="metricsButton" rel="noreferrer" href={UPTIME_HISTORY} target="_blank">Traffic Metrics {externalLinkIcon()}</a>
                    </div>
                })
            }
            tempItems = tempItems.concat({
                key: 1,
                label: 'System Status',
                children: <div>
                    {
                        offline ? <b>System offline</b> :
                            <div>
                                <div className="statusList">
                                    <b>System Health:</b> {determineHealth() ? <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> : <CloseCircleTwoTone twoToneColor={'#ff0000'} />}
                                </div>
                                <div className="statusList">
                                    <div className="textBox">
                                        <ul>
                                            {
                                                item.radar_health ? '' : <li>Cannot find any radar points</li>
                                            }
                                            {
                                                item.lanes_health ? '' : <li>Cannot find any lanes</li>
                                            }
                                            {
                                                item.object_health ? '' : <li>Cannot find any objects</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="statusList">
                                    <b>Human Verification:</b> {item.human_verification ? <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> : <CloseCircleTwoTone twoToneColor={'#ff0000'} />}
                                    <b className="secondB"> Automated Alerts:</b> {item.system_enabled ? <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> : <CloseCircleTwoTone twoToneColor={'#ff0000'} />}
                                </div>
                            </div>
                    }
                </div>
            })
            if (item.temperatures) {
                tempItems = tempItems.concat({
                    key: 2,
                    label: 'System Temperatures',
                    children: <div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <p style={{ width: '150px' }}><b>SR Lane:</b> {celsiusToFahrenheit(item.temperatures['sr_lane']).toFixed(2)}&deg;F</p>
                            <p style={{ width: '150px' }}><b>SR Pair:</b> {celsiusToFahrenheit(item.temperatures['sr_pair']).toFixed(2)}&deg;F</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <p style={{ width: '150px' }}><b>LR Lane:</b> {celsiusToFahrenheit(item.temperatures['lr_lane']).toFixed(2)}&deg;F</p>
                            <p style={{ width: '150px' }}><b>LR Pair:</b> {celsiusToFahrenheit(item.temperatures['lr_pair']).toFixed(2)}&deg;F</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <p style={{ width: '150px' }}><b>RPi:</b> {celsiusToFahrenheit(item.temperatures['rpi']).toFixed(2)}&deg;F</p>
                            <p style={{ width: '150px' }}><b>Enclosure:</b> {celsiusToFahrenheit(item.temperatures['enclosure']).toFixed(2)}&deg;F</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <p style={{ width: '150px' }}><b>Humidity:</b> {(item.temperatures['humidity'] ? item.temperatures['humidity'] : 0).toFixed(2)}%</p>
                        </div>
                    </div>
                })
            }
            setItems(tempItems)
        }
    }, [item, role, offline, determineHealth])

    useEffect(() => {
        if (url !== '') {
            setVideoModalOpen(true)
        }
    }, [url])

    return (
        <div>
            <Card className="infoPanel">
                <Title level={3}>
                    {
                        edit ?
                            <div>
                                Name:
                                <Input
                                    autoFocus
                                    maxLength={100}
                                    placeholder={item.vehicle_name}
                                    value={newName}
                                    onChange={e => setNewName(e.target.value)}
                                    onKeyDown={e => (e.key === 'Enter') ? saveName() : e.key === 'Escape' ? cancelName() : ''}
                                />
                                <Button onClick={saveName}>Save</Button>
                                <Button className="cancelButton" onClick={cancelName}>Cancel</Button>
                            </div> :
                            <div>
                                Name: {item.vehicle_name}
                                {
                                    role !== 'user' && role !== 'analyst' &&
                                    <Button style={{ marginLeft: '5px' }} icon={<EditOutlined />} onClick={e => setEdit(true)} />
                                }
                            </div>
                    }
                </Title>
                <div>
                    <Text strong>Serial Number: </Text><Text>{item.serial_number}</Text>
                </div>

                <Divider className="smallerDivider" />

                <Title level={4}> <Text strong>Localization:</Text></Title>
                <div>
                    <Text className="gpsText" strong>GPS:</Text><Text>{item.gps_latitude}, {item.gps_longitude}</Text>
                    <br />
                    <b>Live:</b>
                    {
                        item.tablet_connected && !offline ? <CheckCircleTwoTone twoToneColor={'#52dd1a'} /> : <CloseCircleTwoTone twoToneColor={'#ff0000'} />
                    }
                </div>

                <Divider className="smallerDivider" />

                <Collapse accordion items={items}></Collapse>
                <Divider className="smallerDivider" />

                <div>
                    <Text strong>Last seen: </Text><Text>{new Date(item.status_datetime).toLocaleString()} </Text>
                </div>
                <Button className="latestButton" onClick={() => setModalOpen(true)}>Latest Alert</Button>
                <LatestAlert open={modalOpen} setOpen={setModalOpen} alert={item} dispatch={dispatch} setUrl={setUrl} />
                <VideoModal open={videoModalOpen} setOpen={setVideoModalOpen} url={url[1]} name={url[0]} />
                <Metrics open={openMetrics} setOpen={setOpenMetrics} item={item} />
            </Card>
        </div>
    );
}

export default InfoPanel;