import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { message } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import AlertMarker from "./AlertMarker";
import { TableRow } from "./TableRow";
import "../styles/map_view.scss"

const MapView = ({ loading, selectedAlert, detections, csvData, setDownloadStatus, selectedCounter, setSelectedCounter, handleDetectionClick, selectedInterval, selectedRange, sortBy, toggleSort }) => {
    const LEVEL_TO_STR = ['No Hazard', 'Lights', 'Sirens']
    const THREAT_LEVEL_TO_STR = ['No Hazard', 'In Lane', 'Lights', 'Sirens']
    const [mapLocation, setMapLocation] = useState({ latitude: 0, longitude: 0 })
    const [unitsStr, setUnitsStr] = useState('')

    const Recenter = () => {
        const map = useMap();
        useEffect(() => {
            if (mapLocation && !!mapLocation.latitude && !!mapLocation.longitude) {
                const location = [mapLocation.latitude, mapLocation.longitude]
                if (!!selectedAlert) {
                    map.setView(location)
                    map.setZoom(18)
                } else {
                    map.setView(location)
                    map.setZoom(13)
                }
            }
        })
    }

    const findFirstLocation = () => {
        for (let [i, d] of detections) {
            if (!!d.location.latitude && !!d.location.longitude) {
                return d.location
            }
        }
    }

    const findMetadata = (serial_number, detection_date) => {
        for (let data of csvData) {
            if (data.serial_number === serial_number && data.detection_date === detection_date) {
                const { data_serial_number, data_detection_date, ...metadata } = data
                return metadata
            }
        }
        return undefined
    }

    const convertAlerts = () => {
        let data = [['Unit', 'Date', 'Trigger Type', 'Trigger Level', 'Threat Level',
            'Threat Distance (ft)', 'Threat Speed (mph)', 'Latitude', 'Longitude',
            'System Enabled', 'Human Verification', 'Light Threshold (sec)', 'Sirens Threshold (sec)'
        ]]
        data[0] = data[0].join(',')
        for (let [i, alert] of detections) {
            alert = { ...alert, ...findMetadata(alert.serial_number, alert.detection_date) }
            let row = [
                alert.vehicle_name,
                new Date(alert.detection_date).toLocaleString().replace(',', ''),
                alert.trigger_type,
                `${alert.trigger_level} (${LEVEL_TO_STR[alert.trigger_level]})`,
                `${alert.threat_level ? `${alert.threat_level}${alert.threat_level === 'N/A' ? '' : ` (${THREAT_LEVEL_TO_STR[alert.threat_level]})`}` : ''}`,
                `${alert.threat_distance ? `${alert.threat_distance === 'N/A' ? '' : alert.threat_distance}` : ''}`,
                `${alert.threat_speed ? `${alert.threat_speed === 'N/A' ? '' : alert.threat_speed}` : ''}`,
                alert.location.latitude,
                alert.location.longitude,
                alert.system_enabled ? 'ON' : 'OFF',
                alert.human_verification ? 'ON' : 'OFF',
                alert.lights_threshold,
                alert.sirens_threshold,
            ]
            data = data.concat([row.join(',')])
        }
        return data.join('\n')
    }

    useEffect(() => {
        let units = []
        detections?.map((d) => {
            if (!units.find((u) => u[1] === d[1].vehicle_name)) {
                units.push([d.serial_number, d[1].vehicle_name])
            }
        })
        units.sort((u1, u2) => u1[0] > u2[0] ? 1 : -1)
        setUnitsStr(units.map(u => u[1]).join('_'))
        if (detections?.length) {
            setMapLocation(findFirstLocation())
        }
    }, [detections])

    useEffect(() => {
        if (selectedAlert) {
            setMapLocation(selectedAlert.location)
        }
    }, [selectedAlert])

    useEffect(() => {
        if (detections?.length && csvData?.length) {
            const blob = new Blob([convertAlerts()], { type: 'text/csv' })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `${unitsStr}-${new Date().toLocaleDateString()}-alerts.csv`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            message.info(`Downloaded ${a.download}. Check your downloads.`)
            setDownloadStatus(false)
        }
    }, [detections, csvData])

    useEffect(() => {
        document.getElementById('table').scrollTo(0, selectedCounter * 33)
    }, [selectedCounter])

    return (
        <div className="alerts">
            <div id='table' className='alertsTable'>
                <table>
                    <thead>
                        <tr>
                            <th className="sort" onClick={() => toggleSort('serial_number')}>Unit {sortBy === '-serial_number' ? <DownOutlined /> : sortBy === 'serial_number' ? <UpOutlined /> : ''}</th>
                            <th className="sort" onClick={() => toggleSort('detection_date')}>Date {sortBy === '-detection_date' ? <DownOutlined /> : sortBy === 'detection_date' ? <UpOutlined /> : ''}</th>
                            <th className="sort" onClick={() => toggleSort('trigger_type')}>Alert Type {sortBy === '-trigger_type' ? <DownOutlined /> : sortBy === 'trigger_type' ? <UpOutlined /> : ''}</th>
                            <th className="sort" onClick={() => toggleSort('trigger_level')}>Alert Level {sortBy === '-trigger_level' ? <DownOutlined /> : sortBy === 'trigger_level' ? <UpOutlined /> : ''}</th>
                            <th>GPS</th>
                        </tr>
                    </thead>
                    {
                        !loading ?
                            detections?.length ?
                                <tbody>
                                    {
                                        detections?.map((d) => {
                                            return (
                                                <TableRow
                                                    key={`${d[1].detection_date}-${d[0]}`}
                                                    handleDetectionClick={handleDetectionClick}
                                                    counter={d[0]}
                                                    rowInfo={d[1]}
                                                    selectedCounter={selectedCounter}
                                                    setSelectedCounter={setSelectedCounter}
                                                    setMapLocation={setMapLocation}
                                                />
                                            )
                                        })
                                    }
                                </tbody> :
                                <tbody>
                                    <tr>
                                        <td colSpan={5} style={{ textAlign: 'center' }}>
                                            No Alerts within the {selectedInterval === 'Custom' ? 'Custom Range' : selectedInterval}
                                        </td>
                                    </tr>
                                    {
                                        selectedInterval === 'Custom' ?
                                            <tr>
                                                <td colSpan={5} style={{ textAlign: 'center' }}>
                                                    {new Date(selectedRange[0]).toLocaleString()} - {new Date(selectedRange[1]).toLocaleString()}
                                                </td>
                                            </tr> : ''
                                    }
                                </tbody> :
                            <tbody>
                                <tr>
                                    <td colSpan={5} style={{ textAlign: 'center' }}>
                                        Loading...
                                    </td>
                                </tr>
                            </tbody>
                    }
                </table>
            </div>
            <div className="map-container">
                <MapContainer
                    center={[!!mapLocation?.latitude ? mapLocation.latitude : 0, !!mapLocation?.longitude ? mapLocation.longitude : 0]}
                    zoom={13}
                    scrollWheelZoom={true}
                    className="map"
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerClusterGroup maxClusterRadius={25}>
                        {
                            detections?.map((d) => <div key={d[0]}>
                                <AlertMarker
                                    serialNumber={d[1].serial_number}
                                    level={d[1].trigger_level}
                                    alert={d[1].name}
                                    selectedCounter={selectedCounter}
                                    setSelectedCounter={setSelectedCounter}
                                    counter={d[0]}
                                    mapLocation={d[1].location}
                                    setMapLocation={setMapLocation}
                                />
                            </div>)
                        }
                    </MarkerClusterGroup>
                    <Recenter />
                </MapContainer>
            </div>
        </div>
    )
}

export default MapView;
