import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { LOGIN_PAGE } from "../../configuration/paths";
import { getAppVersions, getCSVData, getProfile, getSingleAlert, getUnitNames, getVehicleAlerts } from '../../store';
import { Card, Divider, Button, Layout, Popover, Tooltip } from "antd";
import { RedoOutlined, DownOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import OptionsMenu from './components/Menu';
import MapView from './components/MapView';
import { FilterButton } from './components/FilterButton';
import "./styles/index.scss"
import { DetectionModal } from '../utility/components/DetectionModal';
import { VideoModal } from '../utility/components/VideoModal';
import { IntervalSelector } from './components/IntervalSelector';

const { Sider } = Layout;

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedIn = !!useSelector(state => state.app.auth.token);
    const isSpecial = useSelector(state => state.app.profile.role === 'special');
    const alerts = useSelector(state => state.app.alerts);
    const [loading, setLoading] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [units, setUnits] = useState([]);
    const [appVersions, setAppVersions] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [selectedAppVersions, setSelectedAppVersions] = useState([]);
    const [selectedRange, setSelectedRange] = useState([]);
    const [sortBy, setSortBy] = useState('-detection_date');
    const [csvData, setCsvData] = useState([]);
    const [downloadStatus, setDownloadStatus] = useState(false);
    // When this is true, the alerts will be displayed on a map. Otherwise, it will be in a basic grid displaying even more alerts per page.
    const [mapMode, setMapMode] = useState(true);
    // Next 5 are for the modal
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [selectedCounter, setSelectedCounter] = useState(-1);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [selectedInterval, setSelectedInterval] = useState('Past 24 Hours');
    const INTERVALS = [
        'Past 24 Hours',
        'Past Week',
        'Past Month',
        'Custom'
    ]
    const typeDict = {
        None: 'Near Miss Report',
        Debug: 'Debug Report',
        Manual: 'Manual Report',
        Tablet: 'Tablet Report',
        Automatic: 'Automatic Report',
        "FA/NM": 'False Alarm/ Near Miss'
    }
    const levelDict = {
        0: 'No Hazard',
        1: 'Lights',
        2: 'Sirens',
    }

    const toggleSort = (sort) => {
        if (sortBy.endsWith(sort)) {
            if (sortBy.startsWith('-')) {
                setSortBy(sort)
            } else {
                setSortBy(`-${sort}`)
            }
        } else {
            setSortBy(`-${sort}`)
        }
    }

    const compareAppVersions = (aV1, aV2) => {
        let splitAV1 = aV1.split('.').map((num) => parseInt(num))
        let splitAV2 = aV2.split('.').map((num) => parseInt(num))

        let diff = [splitAV1[0] - splitAV2[0], splitAV1[1] - splitAV2[1], splitAV1[2] - splitAV2[2]]

        if (diff[0] !== 0) {
            return -1 * diff[0]
        } else if (diff[1] !== 0) {
            return -1 * diff[1]
        } else {
            return -1 * diff[2]
        }

    }

    const grabUpdates = () => {
        dispatch(getVehicleAlerts({ units: selectedUnits, type: selectedTypes, level: selectedLevels, app_version: selectedAppVersions, range: selectedRange, sortBy: sortBy }));
    }

    const parseFilterDate = (range) => {
        const formatDate = (date) => {
            return new Date(date.replace('_', ' ')).toISOString().replace('Z', '+00:00')
        }
        if (!!range) {
            range = range.replaceAll('_', " ").split('-')
            return [formatDate(range[0].replace('_', ' ')), formatDate(range[1].replace('_', ' '))]
        }
    }

    const findAlert = (unit, alert) => {
        const theAlert = alerts?.find(a => a[1].serial_number === unit && a[1].detection_date === alert)
        if (!!theAlert) {
            setSelectedCounter(theAlert[0])
            setSelectedAlert(theAlert[1])
            return true
        } else {
            return false
        }
    }

    const compare_keys = (one, two) => {
        if (one.length === 2) {
            return one[0] !== two[0] && one[1] !== two[1]
        } else {
            return one !== two
        }
    }

    const getSetState = (filterType) => {
        if (filterType === "unitFilters") {
            return setSelectedUnits
        } else if (filterType === "triggerTypeFilters") {
            return setSelectedTypes
        } else if (filterType === "triggerLevelFilters") {
            return setSelectedLevels
        } else if (filterType === "appVersionFilters") {
            return setSelectedAppVersions
        }
    }

    const manageSelectedKeys = (e) => {
        let alreadySelected = false;
        if (!(e.key === 'selected')) {
            if (!selectedKeys.find(key => key[0] === e.key || (key[0].length === 2 && (key[0][0] === e.key[0] && key[0][1] === e.key[1])))) {
                setSelectedKeys(prevKeys => [...prevKeys, [e.key, e.keyPath[1]]])
            } else {
                setSelectedKeys(selectedKeys.filter(key => (compare_keys(key[0], e.key))))
                alreadySelected = true;
            }
        }
        return alreadySelected
    }

    const manageFilter = (alreadySelected, setFilter, value) => {
        if (!alreadySelected) {
            setFilter(f => [...f, value])
        } else {
            setFilter(f => f.filter((val) => {
                return val !== value
            }))
        }
    }

    const onClick = (e) => {
        const STR_TO_INT = {
            'No Hazard': 0,
            'Lights': 1,
            'Sirens': 2
        }
        if (!loading) {
            let alreadySelected = manageSelectedKeys(e);
            if (e.keyPath[2] === 'filters') {
                if (e.keyPath[1] === 'unitFilters') {
                    manageFilter(alreadySelected, setSelectedUnits, e.key)
                } else if (e.keyPath[1] === 'triggerTypeFilters') {
                    manageFilter(alreadySelected, setSelectedTypes, e.key)
                } else if (e.keyPath[1] === 'triggerLevelFilters') {
                    manageFilter(alreadySelected, setSelectedLevels, STR_TO_INT[e.key])
                } else if (e.keyPath[1] === 'appVersionFilters') {
                    manageFilter(alreadySelected, setSelectedAppVersions, e.key)
                }
            }
        }
    }

    const rangePicked = (date, dateStrings) => {
        if (dateStrings[0].length && dateStrings[1].length) {
            const start = new Date(dateStrings[0]).toISOString().replace('Z', '+00:00')
            const stop = new Date(dateStrings[1]).toISOString().replace('Z', '+00:00')
            setSelectedRange([start, stop])
        }
    }

    const flipDateOrName = (date, oldSeparator, newSeparator) => {
        if (date) {
            date = date.split('T')
            date[1] = date[1].replaceAll(oldSeparator, newSeparator)
            date = date.join('T')
        }
        return date
    }

    const handleDetectionClick = (alert) => {
        setSelectedAlert(alert)
        navigate(`${location.pathname}?unit=${alert.serial_number}&alert=${flipDateOrName(alert.detection_date, ':', '-')}`)
    }

    const handleClose = () => {
        setAlertModalOpen(false);
        navigate(`${location.pathname}`)
    }

    const setInterval = (e) => {
        if (!loading) {
            setSelectedInterval(e.key)
        }
    }

    const startCSVDownload = () => {
        setDownloadStatus(true)
        dispatch(getCSVData({ "alerts": alerts.map((alert) => { return { name: alert[1].detection_date.replaceAll(':', '-'), serial_number: alert[1].serial_number } }) }))
            .then((response) => {
                setCsvData(response.payload)
            })
            .catch(() => {
                setDownloadStatus(false)
            })
    }

    if (!loggedIn) {
        navigate(LOGIN_PAGE)
    }

    useEffect(() => {
        dispatch(getProfile())
        dispatch(getUnitNames())
            .then((response) => {
                setUnits(response.payload.sort((n1, n2) => n1[1] > n2[1] ? 1 : -1))
            })
        dispatch(getAppVersions())
            .then((response) => {
                setAppVersions(response.payload.sort((aV1, aV2) => compareAppVersions(aV1, aV2)))
            })
    }, []);

    useEffect(() => {
        setAlertModalOpen(false)
        const searchParams = new URLSearchParams(location.search)
        const unit = searchParams.get('unit')
        const filter = searchParams.has('filter')
        if (filter) {
            const t = searchParams.get('type')
            const level = searchParams.get('level')
            const date = searchParams.get('range')

            setSelectedUnits([unit])
            setSelectedKeys([[unit, 'unitFilters']])

            if (!!date) {
                setSelectedInterval('Custom')
                setSelectedRange(f => parseFilterDate(date))
            }

            if (t) {
                setSelectedTypes([t])
                setSelectedKeys(prevKeys => [...prevKeys, [t, 'triggerTypeFilters']])
            }

            if (level) {
                const INT_TO_STR = ['No Hazard', 'Lights', 'Sirens']
                setSelectedLevels([parseInt(level)])
                setSelectedKeys(prevKeys => [...prevKeys, [INT_TO_STR[parseInt(level)], 'triggerLevelFilters']])
            }
        } else {
            const alert = searchParams.get('alert')
            if (unit && alert) {
                findAlert(unit, alert)
                setAlertModalOpen(true)
            }
        }
        setPageLoaded(true)
    }, [location])

    useEffect(() => {
        if (pageLoaded) {
            const now = new Date().toISOString().split('.')[0]
            if (selectedInterval === 'Past 24 Hours') {
                const yesterday = new Date(new Date().setHours(new Date().getHours() - 24)).toISOString().split('.')[0]
                setSelectedRange([yesterday, now])
            } else if (selectedInterval === 'Past Week') {
                const lastWeek = new Date(new Date().setHours(new Date().getHours() - 24 * 7)).toISOString().split('.')[0]
                setSelectedRange([lastWeek, now])
            } else if (selectedInterval === 'Past Month') {
                const daysLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()
                const lastMonth = new Date(new Date().setDate(new Date().getDate() - daysLastMonth)).toISOString().split('.')[0]
                setSelectedRange([lastMonth, now])
            }
        }
    }, [selectedInterval, pageLoaded])

    useEffect(() => {
        if (alerts !== undefined) {
            const searchParams = new URLSearchParams(location.search)
            if (searchParams.size > 0) {
                const unit = searchParams.get('unit')
                const alert = flipDateOrName(searchParams.get('alert'), '-', ':')
                if (!findAlert(unit, alert) && !searchParams.has('filter')) {
                    dispatch(getSingleAlert({ serial_number: unit, name: alert }))
                } else {
                    findAlert(unit, alert)
                    setAlertModalOpen(true)
                }
            }
            setLoading(false)
        }
    }, [alerts])

    useEffect(() => {
        setLoading(true)
        if (selectedRange.length && pageLoaded) {
            setSelectedCounter(-1)
            setSelectedAlert(null)
            dispatch(getVehicleAlerts({ units: selectedUnits, type: selectedTypes, level: selectedLevels, app_version: selectedAppVersions, range: selectedRange, sortBy: sortBy }))
        }
    }, [selectedUnits, selectedTypes, selectedLevels, selectedAppVersions, selectedRange, sortBy, pageLoaded])

    useEffect(() => {
        if (url !== '') {
            setVideoModalOpen(true)
        }
    }, [url])

    useEffect(() => {
        if (!downloadStatus) {
            setCsvData([])
        }
    }, [downloadStatus])

    return (
        <div>
            <Layout>
                <Sider theme='light'>
                    <IntervalSelector
                        intervals={INTERVALS}
                        selectedInterval={selectedInterval}
                        setInterval={setInterval}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        rangePicked={rangePicked}
                    />
                    <OptionsMenu
                        onClick={onClick}
                        selectedKeys={selectedKeys.map((sK) => sK[0])}
                        filters={{ 'units': units, 'appVersions': appVersions }}
                        isSpecial={isSpecial}
                    />
                    <div className='popover'>
                        <Popover
                            placement='bottom'
                            content={selectedKeys.length > 0 ? selectedKeys.map(filter => {
                                return (
                                    <div key={String(filter)}>
                                        <FilterButton
                                            value={filter[0]}
                                            setFilter={getSetState(filter[1])}
                                            selectedKeys={selectedKeys}
                                            setSelectedKeys={setSelectedKeys}
                                        />
                                    </div>
                                )
                            }) : 'No filters have been selected.'}
                        >
                            Selected Filters&nbsp;&nbsp;
                            <DownOutlined />
                        </Popover>
                    </div>
                </Sider>

                <Layout>
                    <Card>
                        <div className='change-view'>
                            <Tooltip title='Refresh alerts'>
                                <Button onClick={grabUpdates} icon={<RedoOutlined />}>Refresh</Button>
                            </Tooltip>
                            <Button style={{ marginLeft: '20px', marginRight: '20px' }} disabled={downloadStatus} onClick={() => startCSVDownload()} icon={<DownloadOutlined />}>Download CSV</Button>
                            {downloadStatus && <div style={{ display: 'flex', flexDirection: 'row' }}><p style={{ marginRight: '5px' }}>Gathering CSV data</p><LoadingOutlined /></div>}
                        </div>
                        <Divider />
                        <MapView
                            loading={loading}
                            selectedAlert={selectedAlert}
                            detections={alerts}
                            downloadCSV={csvData}
                            csvData={csvData}
                            setDownloadStatus={setDownloadStatus}
                            selectedCounter={selectedCounter}
                            setSelectedAlert={setSelectedAlert}
                            setSelectedCounter={setSelectedCounter}
                            handleDetectionClick={handleDetectionClick}
                            selectedInterval={selectedInterval}
                            selectedRange={selectedRange}
                            setSelectedRange={setSelectedRange}
                            sortBy={sortBy}
                            toggleSort={toggleSort}
                        />
                    </Card>
                    <DetectionModal
                        open={alertModalOpen}
                        handleClose={handleClose}
                        title={<><b>Date:</b> {new Date(`${selectedAlert?.detection_date}+00:00`).toLocaleString()}<br /><b>Trigger:</b> {typeDict[selectedAlert?.trigger_type]} ({levelDict[selectedAlert?.trigger_level]})</>}
                        left_data={[
                            ['Unit: ', selectedAlert?.vehicle_name ? selectedAlert?.vehicle_name : selectedAlert?.serial_number],
                            ['Location: ', `${selectedAlert?.location.latitude}, ${selectedAlert?.location.longitude}`],
                            ['App Version: ', selectedAlert?.app_version],
                        ]}
                        alert={selectedAlert}
                        setUrl={setUrl}
                    />
                    <VideoModal name={url[0]} url={url[1]} open={videoModalOpen} setOpen={setVideoModalOpen} />
                </Layout>
            </Layout>
        </div>
    )
};

export default Index;
